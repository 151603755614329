import * as React from 'react';
//import { graphql } from "gatsby"
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Layout from '../components/layout';

function Page({ pageContext }) {
  const page = pageContext;

  return (
    <Layout>
      {/* <pre>{JSON.stringify(page)}</pre> */}
      <h1>{page.frontmatter.title}</h1>
      {/* <em>{page.date}</em> - {page.topic}*/}
      <div>
        <MDXRenderer>{page.body}</MDXRenderer>
      </div>
    </Layout>
  );
}

export default Page;

// export const query = graphql`
//   query($id: String!) {
//     mdx(id: { eq: $id }) {
//       frontmatter {
//         title
//       }
//       html
//     }
//   }
// `
